module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-next-seo/gatsby-browser.js'),
      options: {"plugins":[],"openGraph":{"type":"website"},"twitter":{"cardType":"summary"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["de-de","en-us","en-gb","en-au","es-es","es-mx","fr-fr","it-it","ja-jp","ko-kr","pl-pl","pt-br","ru-ru","th-th","tr-tr","zh-tw"],"defaultLanguage":"en-us","generateDefaultLanguagePage":true,"siteUrl":"https://static-staging.rgpub.io/gatsbinger/","redirect":false,"i18nextOptions":{"initImmediate":false,"interpolation":{"escapeValue":false},"ns":["second","first","404"],"defaultNS":"first","load":"currentOnly","keySeparator":".","nsSeparator":":","returnObjects":true,"lowerCaseLng":true,"cleanCode":true,"react":{"useSuspense":true}},"pages":[{"matchPath":"/:lang?/example-one","getLanguageFromPath":true,"excludeLanguages":["de-de","en-us","en-gb","en-au","es-es","es-mx","fr-fr","it-it","ja-jp","ko-kr","pl-pl","pt-br","ru-ru","th-th","tr-tr","zh-tw"]}]},
    },{
      plugin: require('../node_modules/@riotgames/gatsby-plugin-geotargeting/gatsby-browser.ts'),
      options: {"plugins":[],"supportedLocales":["de-de","en-us","en-gb","en-au","es-es","es-mx","fr-fr","it-it","ja-jp","ko-kr","pl-pl","pt-br","ru-ru","th-th","tr-tr","zh-tw"],"defaultLanguage":"en-gb"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-NR5PLXG","includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Song of Nunu","short_name":"Song of Nunu","start_url":"/","background_color":"#6063bc","theme_color":"#6063bc","display":"minimal-ui","icon":"src/images/everson_favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"3d79d584c8cf045ace56aef5ed35f6c6"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
